
import { defineComponent } from "vue";

export default defineComponent({
  name: "AsideHeader",
  setup() {
    return {
      logo: require("@/assets/logo.png")
    };
  }
});
